import React, { useState }   from 'react';
import {useStripe, useElements, PaymentElement, ExpressCheckoutElement} from '@stripe/react-stripe-js';
import { useParams } from 'react-router-dom';
import { usePaymentIntent } from '../contexts/PaymentIntentProvider';



export default function Payment() {
    const [ errorMessage, setErrorMessage ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const { id } = useParams();

    const { clientSecret  } = usePaymentIntent();

    const expressCheckoutOptions = {
        // Height in pixels. Defaults to 44. The width is always '100%'.
        buttonHeight: 55
      }

    const onClick = ({ resolve }) => {
        const options = {
            emailRequired: true
          };
          resolve(options);
    }


    const handleExpressCheckout = async (e) => {
        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
          }

          try {

            const {error: submitError} = await elements.submit();
                if (submitError) {
                setErrorMessage(submitError.message);
                return;
            }

              const { error } = await stripe.confirmPayment({
                    elements,
                    clientSecret,
                    confirmParams: {
                        return_url: `https://theperfectclean.com/tip-thank-you/?tip_request=${id}`
                    }
                });

                if (error) {
                    setErrorMessage(error.message);
                    setLoading(false);
                } else {
                    setErrorMessage('')
                    setLoading(false);
                }
            
          } catch (err) {
            setErrorMessage(err.message);
            setLoading(false);
          }

    }

    const handlePaymentSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
          }

          try {

            const {error: submitError} = await elements.submit();
                if (submitError) {
                setErrorMessage(submitError.message);
                return;
            }

              const { error } = await stripe.confirmPayment({
                    elements,
                    clientSecret,
                    confirmParams: {
                        return_url: `https://theperfectclean.com/tip-thank-you/?tip_request=${id}`
                    }
                });

                if (error) {
                    setErrorMessage(error.message);
                    setLoading(false);
                } else {
                    setErrorMessage('')
                    setLoading(false);
                }
            
          } catch (err) {
            setErrorMessage(err.message);
            setLoading(false);
          }

    }


    return(
        <>
            <ExpressCheckoutElement 
                options={ expressCheckoutOptions }
                onClick={ onClick }
                onConfirm={ handleExpressCheckout } 
            /> 
            <PaymentElement />
            <form onSubmit={handlePaymentSubmit }>
                { errorMessage && <p className="error-message">{errorMessage}</p>}
                { stripe || elements ?<button disabled={!stripe} className="tip-submit-btn">{loading ? 'Loading...' : 'Submit Tip'}</button> : '' }
                
            </form>
        </>
    )
}