import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { usePaymentIntent } from '../contexts/PaymentIntentProvider';
import { getTipRequest } from '../utils/tipRequestHelpers';


const PaymentSelection = () => {
    const { id } = useParams();
  
    const [ selectedTip, setSelectedTip] = useState('');
    const [ customTip, setCustomTip ] = useState('');
    const [ loading, setLoading ] = useState(false);

    const { setClientSecret, setPaymentRequestDetails, paymentRequestDetails } = usePaymentIntent();

    useEffect(() => {
       getTipRequest(id, setPaymentRequestDetails);
    }, [setPaymentRequestDetails, id]);

    const handleTipChange = (e) => {
        const { value } = e.target;
        setSelectedTip(value);

        const customTipInput = document.getElementById('customTip');
        if(value === 'other') {
            customTipInput.style.display = 'block';
        } else {
            customTipInput.style.display = 'none';
            setCustomTip(''); // Reset custom tip
        }

    }

    const handleCustomTipChange = (e) => {
        setSelectedTip(e.target.value);
    }

    const handleTipSubmit = () => {
        setLoading(true);
        const tipAmount = selectedTip === 'other' ? customTip : selectedTip;
        createPaymentIntent(tipAmount * 100);
    }

    const createPaymentIntent = async (amount, currency = 'usd') => {
        try {

            const requestData = {
                amount,
                currency,
                customer: paymentRequestDetails.stripe_customer_id
            }

            const response = await axios.post('https://pest-routes-tipping-az-c5101334ece4.herokuapp.com/api/v1/payments/create/payment-intent', requestData);
            const { client_secret } = response.data;

            if(client_secret) {
                setClientSecret(client_secret);
                setLoading(false);
            } else {
                console.error('No client secret in response');
                setLoading(false);
            }
            
        } catch (err) {
            console.error('error creating payment intent:', err);
            setLoading(false);
        }
    }

    const employeeNames  = (employees) => {
         const cleanersName = employees.map(employee => `${employee.first_name}`).join(' and ');
         return cleanersName
        
    }

    return(
            !paymentRequestDetails ? <p className="center-text">Loading...</p> :
            <div className="payment-container">
                <p className="thank-you-title">Thank you for choosing The Perfect Clean</p>
                <p className="cleaners-title">Tip your cleaners <span className="bold-text">{ paymentRequestDetails ? employeeNames(paymentRequestDetails.employees) : ''}</span></p>
                <div className="tip-amount-container" >
                    <label className={`tip-label ${selectedTip === '25' ? 'tip-label-active' : ''}`}>
                        <input className="tip-radio" type="radio" name="tipAmount" value="25" onChange={handleTipChange} /> $25
                    </label>
                    <label className={`tip-label ${selectedTip === '50' ? 'tip-label-active' : ''}`}>
                        <input className="tip-radio" type="radio" name="tipAmount" value="50" onChange={handleTipChange} /> $50
                    </label>
                    <label className={`tip-label ${selectedTip === '100' ? 'tip-label-active' : ''}`}>
                        <input className="tip-radio" type="radio" name="tipAmount" value="100" onChange={handleTipChange} /> $100
                    </label>
                    <label className={`tip-label ${selectedTip === 'other' ? 'tip-label-active' : ''}`}>
                        <input className="tip-radio" type="radio" name="tipAmount" value="other" onChange={handleTipChange} /> Other
                    </label>
                </div>
                    <div className="custom-tip-container">
                        <input className="custom-input-field" type="tel" id="customTip" placeholder="Enter custom amount" style={{display: 'none'}} onChange={handleCustomTipChange} />
                    </div>
                <button className="tip-submit-btn" onClick={handleTipSubmit}>{loading ? 'Loading...' : (selectedTip ? `Tip $${selectedTip}` : 'Select Your Tip Amount')}</button>
                <p className="gratitude-title">Tips are always appreciated, and never expected.</p>
            </div>
        
    )
}

export default PaymentSelection;