import React, { useState } from 'react';
import ReportPassword from './ReportPassword';
import ReportData from './ReportData';


const Report = () => {
    const [ showReport, setShowReport ] = useState(false);

    const handleSuccess = () => {
        setShowReport(true);
    };

    return (
        <>
            {!showReport && <ReportPassword onSuccess={handleSuccess} />}
            {showReport && <ReportData />}
        </>
    )
}

export default Report;