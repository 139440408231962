import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ReportData = () => {
    const [ reports, setReports ] = useState([]);

    useEffect(() => {
        const fetchReports = async () => {
            try {
                const response = await axios.post('https://pest-routes-tipping-az-c5101334ece4.herokuapp.com/api/v2/reports');
                setReports(response.data);
            } catch (err) {
                console.error('Error fetching report', err);
            }
        }

        fetchReports();
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
        };
    
        // Check if the dateString contains time information
        if (dateString.includes('T')) {
            // For dates with time information, remove the time part before formatting
            const dateWithoutTime = dateString.split('T')[0];
            return new Date(dateWithoutTime).toLocaleDateString('en-US', options);
        } else {
            // For dates without time information, simply format them
            return date.toLocaleDateString('en-US', options);
        }
    };

    const calculateNetAmount = (amount) => {
        const feePercentage = 0.179; // 17.9%
        const feeFixed = 0.3; // $0.30
        const feeAmount = amount * feePercentage + feeFixed;
        return amount - feeAmount;
    };
    
    return(
        <div className="report-container">
        <h1 className="report-title">Tip Reports</h1>
        {reports.map((report, index) => (
            <div className="tip-request-container" key={index}>
                <p className="customer-email">Customer Email: {report.customer_email}</p>
                <p className='amount-total'>Amount: ${calculateNetAmount(report.amount).toFixed(2)}</p>
                <p className="created-date">Created At: { formatDate(report.created_at)}</p>
                <p className="paid-on-date">Customer Tipped On: {formatDate(report.tip_date)}</p>
                <p className="employee-names">Employees: <br/>
                    {report.employees.map((employee, empIndex) => (
                        <span className="employee-name" key={empIndex}>
                            {employee.first_name} {employee.last_name} -
                            {empIndex !== report.employees.length - 1 ? '' : ''}
                            {empIndex !== report.employees.length - 1 ? '' : ''}
                            {` $${(calculateNetAmount(report.amount) / report.employees.length).toFixed(2)}`}
                            <br/>
                        </span>
                    ))}
                </p>
            </div>
        ))}
    </div>
    )
}

export default ReportData;