import React, { useState } from 'react';
import axios from 'axios';

const ReportPassword = ({ onSuccess }) => {
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async () => {
        try {
            const response = await axios.post('https://pest-routes-tipping-az-c5101334ece4.herokuapp.com/password', { password });
            if (response.status === 200) {
                onSuccess();
            } 
        } catch (error) {
            setErrorMessage('Incorrect password');
            setPassword('');
        }
    };

    return (
        <div className="password-container">
            <label className="password-label" htmlFor="password">Enter password to view reports</label>
            <input className="password-input" placeholder="Enter password" id="password" type="text" value={password} onChange={(e) => setPassword(e.target.value)} />
            {errorMessage && <p className="error-message password-error-message">{errorMessage}</p>}
            <button className="password-btn" onClick={handleSubmit}>View Reports</button>
        </div>
    );
};

export default ReportPassword;