import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import StripeContainer from './stripe/StripeContainer';
import './App.css'
import { PaymentIntentProvider } from './contexts/PaymentIntentProvider';
import Report from './components/Report';


function App() {


  return (
    <div className="App">
      <PaymentIntentProvider>
        <Header/>
        <Router>
          <Routes>
                <Route path="/:id" element={<StripeContainer/>} />
                <Route path="/reports" element={<Report/>} />
          </Routes>
        </Router>
      </PaymentIntentProvider>
    </div>
  );
}

export default App;
