import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Payment from '../components/Payment';
import PaymentSelection from '../components/PaymentSelection';
import { usePaymentIntent } from '../contexts/PaymentIntentProvider';


const stripePromise = loadStripe('pk_live_51P0sqiBWPB7fd0YpXYBbWB1HUD7AnMoUkKGrtjk6FjfHLCSKfjnq34bE4xsRlgtu14Ho8eCyh8vV3wjmivocXbQF00vB1SzR9i', {
    stripeAccount: 'acct_1EaS1bIcVamZvsrB',
  });

const StripeContainer = () => {
    
    const { clientSecret  } = usePaymentIntent();

    if(!clientSecret) {
        return <PaymentSelection/>
    }

    const options = { clientSecret };

    return(
        <>
            <PaymentSelection/>
            <div className="payment-container">
                <Elements stripe={ stripePromise } options={ options } >
                    <Payment />
                </Elements>
            </div>
        </>
    )
}

export default StripeContainer;