import React, { createContext, useState, useContext } from 'react';

const PaymentIntentContext = createContext();

export const usePaymentIntent = () =>  useContext(PaymentIntentContext);

export const PaymentIntentProvider = ({ children }) => {
    const [ clientSecret, setClientSecret ] = useState('');
    const [ paymentRequestDetails, setPaymentRequestDetails ] = useState(null);

    const value = {
        clientSecret,
        setClientSecret,
        paymentRequestDetails,
        setPaymentRequestDetails
    };

    return <PaymentIntentContext.Provider value={value}>{children}</PaymentIntentContext.Provider>
};