import axios from 'axios';

export const getTipRequest = async (id, setPaymentRequestDetails) => {

    try {

        const response = await axios.get(`https://pest-routes-tipping-az-c5101334ece4.herokuapp.com/api/v1/tipRequests/${id}`);
        setPaymentRequestDetails(response.data); 

    } catch (err) {

        console.error('Error fetching tip request:', err);
        throw err;

    }
} 